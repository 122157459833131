import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Daniel Felipe Aguirre O.
 * @updateBy Sebastian Rios E.
 * @version: jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomRolesOpciones extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                                       = this.initForm.bind(this);
        this.habilitarBtnSeleccionar                                        = this.habilitarBtnSeleccionar.bind(this);
        this.btnSeleccionar                                                 = this.btnSeleccionar.bind(this);
        this.successTbTodosPorRolesUsuarios_id                              = this.successTbTodosPorRolesUsuarios_id.bind(this);
        // funciones para renderizar la tabla, boton y seleccionar registro
        this.gridOptionsTbTodosPorRolesUsuarios_id                          = Object.assign({},this.gridOptions);
        this.setButtonTbTodosPorRolesUsuarios_id                            = this.setButtonTbTodosPorRolesUsuarios_id.bind(this);
        this.crearBotonTabla                                                = this.crearBotonTabla.bind(this);
        this.accionProcesar                                                 = this.accionProcesar.bind(this);
        // funciones para el boton eliminar
        this.validarEliminar                                                = this.validarEliminar.bind(this);
        this.successEliminar                                                = this.successEliminar.bind(this);
        // funciones para habilitar campos para crear registros en el formulario
        this.btnAgregar                                                     = this.btnAgregar.bind(this);        
        this.habilitarSelectSeccion                                         = this.habilitarSelectSeccion.bind(this);        
        this.cargarSelectSeccion                                            = this.cargarSelectSeccion.bind(this);
        this.successCargarSelectSeccion                                     = this.successCargarSelectSeccion.bind(this);
        this.habilitarSelectOpcion                                          = this.habilitarSelectOpcion.bind(this);
        this.cargarSelectOpciones                                           = this.cargarSelectOpciones.bind(this);
        this.successCargarSelectOpciones                                    = this.successCargarSelectOpciones.bind(this);
        // funciones para la validación y creación de los registros
        this.validacionCrearRegistroJdeskRolesOpciones                      = this.validacionCrearRegistroJdeskRolesOpciones.bind(this);
        this.crearRegistroJdeskRolesOpciones                                = this.crearRegistroJdeskRolesOpciones.bind(this);
        this.successCrearRegistroJdeskRolesOpciones                         = this.successCrearRegistroJdeskRolesOpciones.bind(this);
        //funcion para el boton cancelar
        this.confirmacionCancelar                                           = this.confirmacionCancelar.bind(this);
        this.btnCancelar                                                    = this.btnCancelar.bind(this);        
        // funcion para restablecer campos
        this.RefrescarSelects                                               = this.RefrescarSelects.bind(this);        
    }

    initForm() {

        console.log('Formulario CustomRolesOpciones,  @version: jdesk_1.01.0001, @author:Daniel Felipe Aguirre O, @updateBy: Sebastian Rios E.')
        this.getField('btn_seleccionar').setDisabled(true);
        this.getField('btn_seleccionar').setClick(this.btnSeleccionar);
        this.getField('jdeskrolesusuarios_id').setOnChange(this.habilitarBtnSeleccionar);
        this.getField('secciones_padre_id').setOnChange(this.cargarSelectSeccion);
        this.getField('btn_agregar').setClick(this.btnAgregar);        
        this.getField('principal').setOnChange(this.habilitarSelectSeccion);
        this.getField('seccion').setOnChange(this.habilitarSelectOpcion);
        this.getField('btn_aceptar').setClick(this.validacionCrearRegistroJdeskRolesOpciones);
        this.getField('btn_cancelar').setClick(this.confirmacionCancelar);
    }
    //1.habilita el botón SELECIONAR SI ESTA SETEADO DE FORMA CORRECTA
    habilitarBtnSeleccionar() {

        if (this.getField('jdeskrolesusuarios_id').getValue() !== '' && this.getField('jdeskrolesusuarios_id').valid()) {

            this.getField('btn_seleccionar').setDisabled(false);
            // se debe limpiar los campos y la tabla con cada cambio del select
            this.btnCancelar();
            this.RefrescarSelects();

        } else {

            this.getField('btn_seleccionar').setDisabled(true);            
            this.getField('tb_todosporrolesusuarios_id').toggle(false);
            this.getField('btn_agregar').setVisible(false);
            this.RefrescarSelects();
        }
    }
    //1.1FUNCIONALIDAD PARA EL EVENTO DEL BOTON SELECCIONAR
    btnSeleccionar() {

        if (this.getField('jdeskrolesusuarios_id').getValue() !== '' && this.getField('jdeskrolesusuarios_id').valid()) {
            
            this.getField('btn_seleccionar').setDisabled(true);
            let datos = { datos: {
                jdeskroles_id: this.getField('jdeskrolesusuarios_id').getValue()
            } };
            //datos.datos['jdeskrolesusuarios_id'] = this.getField('jdeskrolesusuarios_id').getValue();
            this.generalFormatPmv = { tipo_servicio: 'heus-rolesopciones', operacion: '2_1', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTbTodosPorRolesUsuarios_id,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage: false,
                }
            );
        } else {
            this.alertGeneral.toggle(true,'* Verifique los datos ingresados','error');
        }
    }
    //1.2 RESULTADO DEPENDIENDO EL ESTADO_P SE RENDERIZA LA TABLA
    successTbTodosPorRolesUsuarios_id(data) {
        
        if (data.estado_p === 200 && data.model && data.model.length >= 1) {
            
            let configCell = new Map();
            this.gridOptionsTbTodosPorRolesUsuarios_id['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            configCell.set('opcion', { headerName: 'Opción'});
            configCell.set('modulo_principal', { headerName: 'Módulo principal'});
            configCell.set('seccion', { headerName: 'Sección'});
            configCell.set('accion', { cellRenderer: this.setButtonTbTodosPorRolesUsuarios_id, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar 
            //botones dentro de la columna asiganada accion
            this.getField('tb_todosporrolesusuarios_id').initData(this.gridOptionsTbTodosPorRolesUsuarios_id,configCell);
            
            this.getField('btn_agregar').setVisible(true);

        }  else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla            
            
            this.alertGeneral.toggle(true,'No hay registros relacionados con el Rol: '+this.getField('texto_select_rol').getValue()+' seleccionado, debe crearlo','error');
            this.getField('tb_todosporrolesusuarios_id').toggle(false);           
            this.getField('btn_agregar').setVisible(true);
        }
    }
    //1.3 RENDERIZAR BOTÓN ELIMINAR EN LA TABLA CUANDO LA FUNCION successTbTodosPorRolesUsuarios_id SE VA POR EL ESTADO_P 200
    setButtonTbTodosPorRolesUsuarios_id(props) {//renderización de botones en la tabla
        
        let fragment = document.createDocumentFragment();
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        buttonEliminar.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonEliminar);        
        return this.createElementJaivana(fragment);
    }
     
      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/
    //1.4 Captura la data del registro seleccionado
    crearBotonTabla(data, boton) {

        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.jdesk_roles_opciones_id}`);
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

      /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Eliminar")} boton 
      **/
    //1.5 Aqui implemento las acciones que pueden ejecutar al oprimir el botón renderizado en la tabla
    accionProcesar(data, boton) {
        
        if (boton === 'Eliminar') {
            
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.validarEliminar(data.jdesk_roles_opciones_id) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);

        }else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    //1.6 Eliminar el registro seleccionado en la tabla
    validarEliminar(jdesk_roles_opciones_id) {

        this.getField('confirmModalCustom').toggle(false);

        if (jdesk_roles_opciones_id !== '') {
            let datos = {
                
                datos: {

                    jdesk_roles_opciones_id: jdesk_roles_opciones_id

                }
            };
            
            this.generalFormatPmv = { tipo_servicio: 'heus-rolesopciones', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
            
                }
            );
        }
    }
    // 1.7 Accion cuando responda el estado del servicio eliminar
    successEliminar(data) {

        if (data.estado_p === 200) {
            
            this.btnSeleccionar();
            this.RefrescarSelects();
            

        } else {

            this.alertGeneral.toggle(true,'No hay datos','error');

        }
    }

    //2.0 Para mostrar y ocultar campos en la acción del botón agregar logica para grabar ya los datos que captura en el front
    btnAgregar(){        
        
        if (this.getField('jdeskrolesusuarios_id').getValue() !== '' && this.getField('jdeskrolesusuarios_id').valid()) {

            this.getField('btn_agregar').setVisible(false);
            this.getField('principal').setVisible(true);
            this.getField('seccion').setVisible(true);
            this.getField('opcion').setVisible(true);
            this.getField('btn_aceptar').setVisible(true);
            this.getField('btn_cancelar').setVisible(true);
            this.getField('estadosactivos_id').setVisible(true);
            this.getField('seccion').setDisabled(true);   
            this.getField('opcion').setDisabled(true);
            this.getField('estadosactivos_id').setDisabled(true);
        }
    }    
    //3.1 habilita select sección
    habilitarSelectSeccion(){

        if (this.getField('principal').getValue() !== '' && this.getField('principal').valid()) {            
            
            
            this.getField('seccion').setDisabled(false);
            this.getField('seccion').setValue('');
            this.getField('seccion').setError(false, '');            
        } else {
            
            this.getField('seccion').setDisabled(true);
            this.getField('seccion').setValue('');   
            this.getField('seccion').setError(false, '');    

        }
    }
    //3.2 consumir servicio desde custom para el select de seccion
    cargarSelectSeccion(){        

        if (this.getField('principal').getValue() !== '' && this.getField('principal').valid() && this.getField('secciones_padre_id').getValue() !== '' && this.getField('secciones_padre_id').valid()) {
                      
            let datos={ datos: {
                secciones_padre_id: this.getField('secciones_padre_id').getValue()                
            }};
            this.generalFormatPmv = { tipo_servicio: '100', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCargarSelectSeccion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }      
    }
    //3.3 Dependiendo el estado_p que responde el servicio renderizar select con las opciones
    successCargarSelectSeccion(data){
        
        this.getField('seccion').setOptions([{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }]);        
        
        if(data.estado_p === 200 && data.model.length >=1){            
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.id;
            dataOp['text'] = item.nombre;
            dataOp['campos_cambian'] = { };
            opciones.push(dataOp); })
            this.getField('seccion').setOptions(opciones);            
        }else if(data.estado_p === 404){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            let dataOp = {}
            dataOp['value'] = this.getField('secciones_padre_id').getValue();
            dataOp['text'] = this.getField('principal').getValue()
            dataOp['campos_cambian'] = {seccion_id: this.getField('principal').getValue()  };
            opciones.push(dataOp);
            this.getField('seccion').setOptions(opciones);
        }else{
            this.getField('seccion').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('seccion').setDisabled(true)            
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');            
        }
    }
    //3.4 habilita select opción y cargar funcion para que consuma el servicio
    habilitarSelectOpcion(){

        if (this.getField('seccion').getValue() !== '' && this.getField('seccion').valid() && this.getField('secciones_padre_id').getValue() !== '' && this.getField('secciones_padre_id').valid()) {
    
            this.getField('opcion').setDisabled(false);            
            this.getField('jdeskopciones_id').setValue('');
            this.cargarSelectOpciones();
                
                
        } else {
            // como viene en cascada habilita o deshabilita campos
            this.getField('opcion').setDisabled(true);
            this.getField('estadosactivos_id').setDisabled(true);
            this.getField('opcion').setValue('');
            this.getField('jdeskopciones_id').setValue('');
            this.getField('estadosactivos_id').setValue('');
            this.getField('opcion').setError(false, '');
            this.getField('estadosactivos_id').setError(false, '');
    
        }
    }

    //3.5 consumir servicio desde custom para el select de opcion
    cargarSelectOpciones(){
                 
        if (this.getField('principal').getValue() !== '' && this.getField('principal').valid() && this.getField('seccion').getValue() !== '' && this.getField('seccion').valid() && this.getField('secciones_padre_id').getValue() !== '' && this.getField('secciones_padre_id').valid() ) {
            
            let datos={ datos: {
                //principal: this.getField("principal").getValue(),
                //seccion: this.getField("seccion").getValue()    
                secciones_id: Number(this.getField("seccion").getValue())
            }};            
            this.generalFormatPmv = { tipo_servicio: '101', operacion: '1_4', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCargarSelectOpciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }      
    }
    //3.6 Dependiendo el estado_p que responde el servicio renderizar select con las opciones
    successCargarSelectOpciones(data){
        
        this.getField('opcion').setOptions([{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }]);        
        
        if(data.estado_p === 200 && data.model.length >=1){            
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.id;
            dataOp['text'] = item.nombre_formulario;
            dataOp['campos_cambian'] = { jdeskopciones_id: item.id};
            opciones.push(dataOp); })
            this.getField('opcion').setOptions(opciones);
            this.getField('estadosactivos_id').setValue('');
            this.getField('estadosactivos_id').setDisabled(false);
            
        }else{            
            
            this.getField('opcion').setError(false, '');
            this.getField('estadosactivos_id').setError(false, '');            
            this.getField('estadosactivos_id').setValue('');
            this.getField('estadosactivos_id').setDisabled(true);
            this.getField('opcion').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('opcion').setDisabled(true)            
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    // 4.0 validar registro para proceder a la creación
    validacionCrearRegistroJdeskRolesOpciones(){        
        
        if (this.getField('principal').valid() && this.getField('principal').getValue() !== '' &&
            this.getField('seccion').valid() && this.getField('seccion').getValue() !== '' &&
            this.getField('opcion').valid() && this.getField('opcion').getValue() !== '' &&
            this.getField('estadosactivos_id').valid() && this.getField('estadosactivos_id').getValue() !== '' &&
            this.getField('jdeskrolesusuarios_id').getValue() !== '' && this.getField('jdeskopciones_id').getValue() !== '') {
                let datos = { datos: {} };
                datos.datos['jdeskroles_id'] = this.getField('jdeskrolesusuarios_id').getValue();
                datos.datos['jdeskopciones_id'] = this.getField('jdeskopciones_id').getValue();
                this.generalFormatPmv = { tipo_servicio: 'heus-rolesopciones', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send
                ({
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.crearRegistroJdeskRolesOpciones,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
    }
    //4.1 dependiendo el estado de la respuesta del servicio de validación sigue el proceso de creación
    crearRegistroJdeskRolesOpciones(data) {
        if (data.estado_p === 200) {
            
            this.alertGeneral.toggle(true, 'Ya existe la relación con este Rol: '+this.getField('texto_select_rol').getValue()+' y opción seleccionada. No se permite duplicar.', 'error');

        } else if (data.estado_p === 404) {
            let datos = { datos: {} };
            datos.datos['jdeskroles_id'] = this.getField('jdeskrolesusuarios_id').getValue();
            datos.datos['jdeskopciones_id'] = this.getField('jdeskopciones_id').getValue();
            datos.datos['estadosactivos_id'] = this.getField('estadosactivos_id').getValue();        
            this.generalFormatPmv = { tipo_servicio: 'heus-rolesopciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successCrearRegistroJdeskRolesOpciones,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        } else {
            this.alertGeneral.toggle(true, "Error en la petición.", 'error');
        }
    }
    //4.2 respuesta de la peticion del servio de crear
    successCrearRegistroJdeskRolesOpciones(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            
            this.btnSeleccionar();
            this.getField('btn_agregar').setVisible(true);
            this.RefrescarSelects();     

        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }
    }
    //5.0 Confirmarción para cancelar
    confirmacionCancelar(){
        
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelar();
            this.RefrescarSelects();
            this.getField('btn_seleccionar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    //5.1 funcionalidad boton cancelar
    btnCancelar() {

        //Ocultar campos y botones
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_agregar').setVisible(false);
        this.getField('estadosactivos_id').setVisible(false);
        this.getField('opcion').setVisible(false);
        this.getField('seccion').setVisible(false);
        this.getField('principal').setVisible(false);
        //Limpiar la tabla
        this.gridOptionsTbTodosPorRolesUsuarios_id['rowData'] = [];
        this.getField('tb_todosporrolesusuarios_id').initData(this.gridOptionsTbTodosPorRolesUsuarios_id);
        this.getField('tb_todosporrolesusuarios_id').toggle(false);
        //Eliminar mensajes de error en los campos
        this.getField('jdeskopciones_id').setError(false, '');
        this.getField('secciones_padre_id').setError(false, '');
        this.getField('estadosactivos_id').setError(false, '');
        this.getField('opcion').setError(false, '');
        this.getField('seccion').setError(false, '');
        this.getField('principal').setError(false, '');
        //this.getField('jdeskrolesusuarios_id').setError(false, '');    
        this.getField('jdeskrolesusuarios_id').setError(false, '');
        //Limpiar valores seteados en los campos
        this.getField('jdeskopciones_id').setValue('');
        this.getField('secciones_padre_id').setValue('');
        this.getField('estadosactivos_id').setValue('');
        this.getField('opcion').setValue('');
        this.getField('seccion').setValue('');
        this.getField('principal').setValue('');
        //this.getField('jdeskrolesusuarios_id').setValue('');
        //this.getField('jdeskrolesusuarios_id').setValue('');
        this.getField('texto_select_rol').setValue('');
        
    }
    //6.0 Refrescar campos especificos
    RefrescarSelects (){

        //Ocultar campos
        this.getField('principal').setVisible(false);
        this.getField('seccion').setVisible(false);
        this.getField('opcion').setVisible(false);
        this.getField('estadosactivos_id').setVisible(false);
        this.getField('btn_aceptar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        //Limpiar campos
        this.getField('principal').setValue('');
        this.getField('seccion').setValue('');
        this.getField('opcion').setValue('');
        this.getField('jdeskopciones_id').setValue('');    
        this.getField('secciones_padre_id').setValue('');
        this.getField('estadosactivos_id').setValue('');
        //Borrar mensajes de error en los campos
        this.getField('principal').setError(false, '');
        this.getField('seccion').setError(false, '');
        this.getField('opcion').setError(false, '');
        this.getField('jdeskopciones_id').setError(false, '');
        this.getField('secciones_padre_id').setError(false, '');
        this.getField('estadosactivos_id').setError(false, '');
    }
}

FormJaivana.addController("heus-rolesopciones", CustomRolesOpciones);
export default CustomRolesOpciones