import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomDocumentosPorVendedor
 * @author: Cristian Ciro
 * @version: jdesk_1.01.0002
 * @updateBy Sebastian Rios E.
 **/
class CustomDocumentosPorVendedor extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.consultarDatos             = this.consultarDatos.bind(this);
        this.successConsultarDatos      = this.successConsultarDatos.bind(this);

        this.gridOptionsDocumentos                                  = Object.assign({},this.gridOptions);
        this.onSelectionChangedT                                    = this.onSelectionChangedT.bind(this);
        this.gridOptionsDocumentos["onSelectionChanged"]            = this.onSelectionChangedT;
        this.limpiarCampos              = this.limpiarCampos.bind(this);
        this.verImagen                  = this.verImagen.bind(this);

        this.formatterGeneral           = this.formatterGeneral.bind(this);
        this.numeroConsecutivo          = this.numeroConsecutivo.bind(this);
        this.successNumeroConsecutivo   = this.successNumeroConsecutivo.bind(this);
        this.habilitarBtnConsultar      = this.habilitarBtnConsultar.bind(this);
        this.limpiarConsecutivo         = this.limpiarConsecutivo.bind(this);
        this.valorAutorizadoTbChange    = this.valorAutorizadoTbChange.bind(this);
        this.autorizarTodo              = this.autorizarTodo.bind(this);
        this.successAutorizarTodo       = this.successAutorizarTodo.bind(this);
        this.successValorAutorizado     = this.successValorAutorizado.bind(this);
        this.setButtonTbRejilla         = this.setButtonTbRejilla.bind(this);
        this.crearBotonTabla            = this.crearBotonTabla.bind(this);
        this.accionProcesar             = this.accionProcesar.bind(this);
        this.cerrarConsulta             = this.cerrarConsulta.bind(this);
        this.cerrarConsultaParcial      = this.cerrarConsultaParcial.bind(this);
        this.successCerrarConsecutivo   = this.successCerrarConsecutivo.bind(this);
        this.confirmCerrarConsecutivo   = this.confirmCerrarConsecutivo.bind(this);
        this.enableBtnUpdateAll         = false;
        this.arrayTemp                  = [];
        this.estado_cierre              = "CD";
    }

    onSelectionChangedT(){

    }

    initForm(){
        console.log('Formulario CustomDocumentosPorVendedor,  @version: jdesk_1.01.0002, @author:Cristian Ciro, @updateBy Sebastian Rios E.');

        //this.getField("consultar").setDisabled(true);
        this.getField("consultar").setClick(this.consultarDatos);
        this.getField("vendedor_nombre").setOnChange(()=>{this.limpiarCampos();});
        this.getField("fecha").setDisabled(true);
        //this.getField("vendedor_id").setOnChange(this.numeroConsecutivo);
        //this.getField("vendedor").setOnChange(this.habilitarBtnConsultar);
        this.getField("numero_consecutivo").setOnChange(()=>{this.limpiarConsecutivo();});
        //this.getField("fecha").setOnChange(this.habilitarBtnConsultar);
        //this.getField("valor").setOnChange(this.habilitarBtnConsultar);
        this.getField('btn_autorizartodo').setClick(this.autorizarTodo);
        this.getField('btn_rechazar').setClick(this.cerrarConsulta);

        //this.getField("btn_cerrar").setClick(this.cerrarConsulta);
        //this.getField("btn_cerrar_parcial").setClick(this.cerrarConsultaParcial);
    }

    autorizarTodo(){
        if (this.getField("vendedor").valid() && this.getField("vendedor_nombre").valid() &&
        this.getField('vendedor_id').getValue() !== '' && this.getField('numero_consecutivo').getValue() !== ''
        && this.getField("nombre_centro_costo").valid() && this.getField("nombre_area").valid()
        ) {
            let datos = { 
                datos: {
                    vendedor_id: this.getField("vendedor_id").getValue(),
                    numero_consecutivo: parseInt(this.getField("numero_consecutivo").getValue())
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: 'autorizar_todos', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successAutorizarTodo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    cerrarConsultaParcial(){
        if (this.getField("detalle").valid() &&
        this.getField("nombre_centro_costo").valid() && this.getField("nombre_area").valid()
        ) {
            this.getField("detalle").setError(false,"");
            this.getField('confirmModalCustom').setTitleAndContent('Consecutivo', `¿Desea cerrar parcialmente el consecutivo?`);
            this.getField('confirmModalCustom').setClickDialog(()=> {
                this.estado_cierre = "CP";
                this.confirmCerrarConsecutivo();
            });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`);
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    cerrarConsulta(){
        if (this.getField("detalle").valid()&&
        this.getField("nombre_centro_costo").valid() && this.getField("nombre_area").valid()) {
            this.getField("detalle").setError(false,"");
            this.getField('confirmModalCustom').setTitleAndContent('Consecutivo', `¿Desea cerrar definitivamente el consecutivo?`);
            this.getField('confirmModalCustom').setClickDialog(()=> {
                this.estado_cierre = "P";
                this.confirmCerrarConsecutivo();
            });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`);
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    confirmCerrarConsecutivo(){
        this.getField('confirmModalCustom').toggle(false);
        let datos = { 
            datos: {
                codigo_vendedor: this.getField("vendedor").getValue(),
                numero: parseInt(this.getField("numero_consecutivo").getValue()),
                detalle: this.getField("detalle").getValue(),
                estado:this.estado_cierre,
                email: this.getField("correo").getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: 'rechazar', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: datos,
            success: this.successCerrarConsecutivo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: true
        });
    }

    successCerrarConsecutivo(data){
        if(data.estado_p === 200){
            this.getField("vendedor").setValue("");
            this.getField("vendedor_nombre").setValue("");
            this.limpiarCampos();
        }
    }

    successAutorizarTodo(data){
        if (data.estado_p === 200) {
            this.getField("vendedor").setValue("");
            this.getField("vendedor_nombre").setValue("");
        }else if (data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al actualizar.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        this.enableBtnUpdateAll = false;
        this.getField('btn_autorizartodo').setDisabled(true);
    }
    
    limpiarConsecutivo(){
        this.arrayTemp = [];
        this.enableBtnUpdateAll = false;
        this.gridOptionsDocumentos['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsDocumentos);
        this.getField('rejilla').toggle(false);
        this.getField('valor_autorizado').setVisible(false);
        this.getField('diferencia').setVisible(false);
        
        this.getField('btn_rechazar').setVisible(false);
        this.getField('btn_autorizartodo').setVisible(false);
        this.getField('detalle').setVisible(false);
        //this.getField('btn_cerrar').setVisible(false);
        //this.getField('btn_cerrar_parcial').setVisible(false);
        if (this.getField('numero_consecutivo').getValue() === '') {
            this.getField("fecha").setValue(new Date().toISOString().split('T')[0]);
            this.getField("fecha").setError(false,'');
            this.getField("valor_mostrar").setValue('');
            this.getField("valor").setValue('');
            this.getField("valor_mostrar").setError(false,'');
        }
    }
    
    habilitarBtnConsultar(){
        if (this.getField("vendedor").getValue() !== '' && this.getField("vendedor_nombre").getValue() !== '' &&
        this.getField("numero_consecutivo").getValue() !== '' && this.getField("fecha").getValue() !== '' &&
        this.getField("valor_mostrar").getValue() !== '' && this.getField("valor").getValue() !== '') {
            this.getField("consultar").setDisabled(false);
        }else{
            this.getField("consultar").setDisabled(true);
        }
    }
    
    numeroConsecutivo(){
        if(this.getField("vendedor").valid() && this.getField("vendedor_id").getValue() !== ''){
            this.datosAgregar={ 
                datos:{
                    codigo_vendedor: this.getField("vendedor").getValue()
                }
            };
   
            this.generalFormatPmv = { tipo_servicio: 'maes-vendedoresgastosconsecutivos', operacion: '1_4', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successNumeroConsecutivo,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successNumeroConsecutivo(data){
        // console.log('data successNumeroConsecutivo:\n',data);
        if (data.estado_p === 200 && data.data.length >= 1) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let valorAnticipado = {
                    "data": {"valor": item.registro_contable}
                };
                let dataOp = {}
                dataOp['value'] = item.numero_vendedor;
                dataOp['text'] = item.numero_vendedor;
                dataOp['campos_cambian'] = {
                    fecha: item.fecha, 
                    valor_mostrar: this.formatterGeneral(valorAnticipado,'valor'), 
                    valor: item.registro_contable,
                    detalle: item.detalle 
                };
                opciones.push(dataOp);
            });
            this.getField('numero_consecutivo').setOptions(opciones);
            this.getField('numero_consecutivo').setValue('');
            this.getField('numero_consecutivo').setVisible(true);
            this.getField('fecha').setVisible(true);
            this.getField('valor_mostrar').setVisible(true);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No se encuentra consecutivos para el vendedor', 'error');
            this.getField('numero_consecutivo').setVisible(false);
            this.getField('fecha').setVisible(false);
            this.getField('valor_mostrar').setVisible(false);
            this.getField("consultar").setVisible(false);
        }
    }
    
    limpiarCampos(){
        if(this.getField("vendedor_nombre").getValue() !== ""){
            this.getField("vendedor_nombre").valid();
            this.getField("consultar").setVisible(true);
            this.getField("numero_consecutivo").setVisible(true);
            this.getField("fecha").setVisible(true);
            this.getField("valor_mostrar").setVisible(true);
            this.numeroConsecutivo();
        }else {
            this.getField("centro_costo").setValue("");
            this.getField("area").setValue("");
            this.getField("nombre_centro_costo").setValue("");
            this.getField("nombre_area").setValue("");
            this.arrayTemp = [];
            this.enableBtnUpdateAll = false;
            this.gridOptionsDocumentos['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsDocumentos);
            this.getField('rejilla').toggle(false);
            this.getField("numero_consecutivo").setVisible(false);
            //this.getField("btn_cerrar").setVisible(false);
            //this.getField("btn_cerrar_parcial").setVisible(false);
            this.getField("detalle").setVisible(false);
            this.getField("detalle").setValue("");
            this.getField("detalle").setError(false,"");
            this.getField("fecha").setVisible(false);
            this.getField("valor_mostrar").setVisible(false);
            this.getField("consultar").setVisible(false);
            this.getField("numero_consecutivo").setOptions([{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }]);
            this.getField("fecha").setValue(new Date().toISOString().split('T')[0]);
            this.getField("valor_mostrar").setValue('');
            this.getField("valor").setValue('');
            this.getField("vendedor_id").setValue('');
            this.getField("fecha").setError(false,'');
            this.getField("valor_mostrar").setError(false,'');
            this.getField("numero_consecutivo").setError(false, '');
            this.getField('valor_autorizado').setVisible(false);
            this.getField('diferencia').setVisible(false);
            this.getField('btn_rechazar').setVisible(false);
            this.getField('btn_autorizartodo').setVisible(false);
        }
    }

    consultarDatos(){
        this.arrayTemp = [];
        if(this.getField("vendedor").valid() && this.getField("vendedor_nombre").valid() && this.getField("numero_consecutivo").valid()){
            this.getField("consultar").setDisabled(true);
            this.datosAgregar={ 
                datos:{
                    vendedor_id: this.getField("vendedor_id").getValue(),
                    numero_consecutivo: parseInt(this.getField("numero_consecutivo").getValue())
                }
            };
   
            this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: '2_1', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successConsultarDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    successConsultarDatos(data){
        this.getField("consultar").setDisabled(false);
        if(data.estado_p === 200){
            let totalValorAutorizado = 0;
            data.data.forEach((item)=>{
                totalValorAutorizado += item.valor_autorizado;
                if (item.aceptado === 'N' && this.enableBtnUpdateAll === false) {
                    this.enableBtnUpdateAll = true;
                    this.getField('btn_rechazar').setDisabled(false);
                    this.getField('detalle').setDisabled(false);
                }
                if(item.aceptado === 'S'){
                    this.getField('btn_rechazar').setDisabled(true);
                    this.getField('detalle').setDisabled(true);
                }
            });

            let valor_autorizado = {
                "data": {"valor": totalValorAutorizado.toFixed(2)}
            };
            this.getField('valor_autorizado').setValue(this.formatterGeneral(valor_autorizado,'valor'));
            
            let diferencia = Number(this.getField('valor').getValue()) - Number(totalValorAutorizado.toFixed(2));
            let valorAnticipado = {
                "data": {"valor": diferencia}
            };
            this.getField('diferencia').setValue(this.formatterGeneral(valorAnticipado,'valor'));
            
            this.getField('valor_autorizado').setVisible(true);
            this.getField('diferencia').setVisible(true);
            this.getField('btn_rechazar').setVisible(true);
            this.getField('btn_autorizartodo').setVisible(true);

            this.getField('detalle').setVisible(true);
            this.getField("detalle").setError(false,"");
            //this.getField('btn_cerrar').setVisible(true);
            //this.getField('btn_cerrar_parcial').setVisible(true);
            if (this.enableBtnUpdateAll)
                this.getField('btn_autorizartodo').setDisabled(false);
            else
                this.getField('btn_autorizartodo').setDisabled(true);
            

            // console.log('data.data:\n',data.data);
            
            let idRegistro = 0;
            for (let i = 0; i < data.data.length; i++) {
                let objetoFinal = {};
                const asignarObject = ()=>{
                    objetoFinal = {
                        "id": data.data[i].id,
                        "vendedores_tipos_documentos_id": data.data[i].vendedores_tipos_documentos_id,
                        "tercero_id": data.data[i].tercero_id,
                        "fecha": data.data[i].fecha,
                        "nombre_documento": data.data[i].nombre_documento,
                        "valor": data.data[i].valor,
                        "detalle": data.data[i].detalle,
                        "fecha_sistema": data.data[i].fecha_sistema,
                        "usuarios_id": data.data[i].usuarios_id,
                        "aceptado": data.data[i].aceptado,
                        "consecutivo": data.data[i].consecutivo,
                        "valor_autorizado": data.data[i].valor_autorizado === undefined || data.data[i].valor_autorizado === null || data.data[i].valor_autorizado === "" ? 0 : data.data[i].valor_autorizado,
                        "codigo_documento": data.data[i].codigo_documento,
                        "imagen1": data.data[i].url_imagen,
                        "imagen2": "",
                        "imagen3": ""
                    };
                    return objetoFinal;
                };
                if (this.arrayTemp.length >= 1) {
                    for (let e = 0; e < this.arrayTemp.length; e++) {
                        if (data.data[i].id === this.arrayTemp[e].id) {
                            if(this.arrayTemp[e].imagen1 !== ""){
                                if(this.arrayTemp[e].imagen2 !== ""){
                                    if(!(this.arrayTemp[e].imagen3 !== "")){
                                        this.arrayTemp[e]["imagen3"] = data.data[i].url_imagen;
                                    }
                                }else{
                                    this.arrayTemp[e]["imagen2"] = data.data[i].url_imagen;
                                }
                            }else{
                                this.arrayTemp[e]["imagen1"] = data.data[i].url_imagen;
                            }
                        }else{
                            asignarObject();
                        }
                    }
                    if (data.data[i].id !== idRegistro) {
                        if (Object.keys(objetoFinal).length >= 1){
                            this.arrayTemp.push(objetoFinal);
                            idRegistro = data.data[i].id;
                        }
                    }
                }else{
                    asignarObject();
                    this.arrayTemp.push(objetoFinal);
                }
            }
            // console.log('this.arrayTemp:\n',this.arrayTemp);
            
                
            let configCell = new Map();
            // this.gridOptionsDocumentos['rowData'] = data.data;
            this.gridOptionsDocumentos['rowData'] = this.arrayTemp;
            this.gridOptionsDocumentos["onSelectionChanged"]            = this.onSelectionChangedT;
            //configCell.set('direccion', {headerName: 'Dirección'});
            //configCell.set('telefonos', {headerName: 'Teléfonos'});
            configCell.set('fecha',{cellRenderer:function(props){return props.data.fecha.split("T")[0]},field:'fecha'});
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:(props)=>{return this.formatterGeneral(props,'valor');},field:'valor'});
            configCell.set('valor_autorizado',{cellStyle: {backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)'}, 
                //editable: true, 
                //onCellValueChanged: this.valorAutorizadoTbChange, 
                valueFormatter:(props)=>{return this.formatterGeneral(props,'valor_autorizado');},field:'valor_autorizado'});
            configCell.set('accion', {cellRenderer:this.setButtonTbRejilla, headerName: 'Acción', sortable: false, filter: false, width:400});
            this.getField('rejilla').initData(this.gridOptionsDocumentos, configCell);
        }else{
            this.gridOptionsDocumentos['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsDocumentos);
            this.getField('rejilla').toggle(false);
        }
    }

    formatterGeneral(props,clave){
        let nStr = clave === 'valor' ? props.data.valor : props.data.valor_autorizado;
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1.$2');
        }
        if(x[1] !== undefined)
            return x1 + `,${x[1]}`;
        else
            return x1 + ',00';
    }


    valorAutorizadoTbChange(props){
        if (props.data.codigo_articulo==='.')
            return;
        let seguir = this.getField('rejilla').cellValid(props.oldValue, props.newValue,"^(\\d{1,13})(\\.\\d{1,2})?$");
        if (isNaN(props.data.valor_autorizado) || seguir===false) {
            if(props.newValue > 9999999999999){
                this.getField('confirmModalCustom').setTitleAndContent('Longitud Incorrecta', `El valor debe estar entre las longitudes (1,9999999999999)`);
                this.getField('confirmModalCustom').setClickDialog(()=> {this.consultarDatos(); this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna valor autorizado, no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.consultarDatos(); this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }
        }else if(seguir){       
            if (Number(props.newValue) <= Number(props.data.valor)) {
                let valor_actual = props.newValue;
                let datos = { datos: {
                    vendedor_id: Number(props.data.id),
                    valor_autorizado: Number(valor_actual)
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-documentosporvendedor', operacion: 'modificarvalorautorizado', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successValorAutorizado,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Error', `El número ingresado, debe ser inferior al número de la columna valor.`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.consultarDatos(); this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }
            
        }
    }
    
    successValorAutorizado(data){
        if (data.estado_p === 200) {
            this.consultarDatos();
        }else if (data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al actualizar.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    setButtonTbRejilla(props){
        let fragment = document.createDocumentFragment();
        let buttonVerImagen1 = this.crearBotonTabla(props.data, 'VER IMAGEN 1');
        let buttonVerImagen2 = this.crearBotonTabla(props.data, 'VER IMAGEN 2');
        let buttonVerImagen3 = this.crearBotonTabla(props.data, 'VER IMAGEN 3');

        fragment.appendChild(buttonVerImagen1);
        fragment.appendChild(buttonVerImagen2);
        fragment.appendChild(buttonVerImagen3);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        let imagen1 = data.imagen1;
        let imagen2 = data.imagen2;
        let imagen3 = data.imagen3;
        switch (boton) {
            case 'VER IMAGEN 1':
                button.setAttribute("id", `buttonImagen1_${data.id}`);
                if (imagen1 !== undefined && imagen1 !== null && imagen1 !== "")
                    button.setAttribute("class","buttonStyleImg");
                else
                    button.setAttribute("class","buttonStyleImgDisabled");
                break;

            case 'VER IMAGEN 2':
                button.setAttribute("id", `buttonImagen2_${data.id}`);
                if (imagen2 !== undefined && imagen2 !== null && imagen2 !== "")
                    button.setAttribute("class","buttonStyleImg");
                else
                    button.setAttribute("class","buttonStyleImgDisabled");
                break;

            case 'VER IMAGEN 3':
                button.setAttribute("id", `buttonImagen3_${data.id}`);
                if (imagen3 !== undefined && imagen3 !== null && imagen3 !== "")
                    button.setAttribute("class","buttonStyleImg");
                else
                    button.setAttribute("class","buttonStyleImgDisabled");
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };

        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click} boton 
      */
    accionProcesar(data, boton) {
        if (boton === 'VER IMAGEN 1') {
            if (data.imagen1 !== "" && data.imagen1 !== " " && data.imagen1 !== null && data.imagen1 !== undefined) {
                this.verImagen(data.imagen1);
            }
        } else if (boton === 'VER IMAGEN 2') {
            if (data.imagen2 !== "" && data.imagen2 !== " " && data.imagen2 !== null && data.imagen2 !== undefined) {
                this.verImagen(data.imagen2);
            }
        }else if (boton === 'VER IMAGEN 3') {
            if (data.imagen3 !== "" && data.imagen3 !== " " && data.imagen3 !== null && data.imagen3 !== undefined) {
                this.verImagen(data.imagen3);
            }
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    verImagen(url_imagen){
        // console.log('url_imagen verImagen:\n',url_imagen);
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos=`?body={"datos":{"url":"${url_imagen}"},"generales":{"tipo_servicio":"maes-documentosporvendedor","operacion":"url_imagen","operacion_tipo":"consulta"}}`;
        fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
            })
        })
        .then(response => {
            if(response.status!==200){                    
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        }).then(stream => new Response(stream))
            .then(response => {
                return response.blob()
            })
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => console.error(err));
    }

    currencyFormatterGeneral(number){
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
                decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number){
        return Number(number).toString().split('.')[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


}
FormJaivana.addController("maes-documentosporvendedor",CustomDocumentosPorVendedor);
export default CustomDocumentosPorVendedor