import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomModificarPrecio
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomModificarPrecio extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                   = this.initForm.bind(this);
        this.validarCodigo                              = this.validarCodigo.bind(this);
        this.sucessValidarCodigo                        = this.sucessValidarCodigo.bind(this);
        this.changeKeyUpCustom                          = this.changeKeyUpCustom.bind(this);
        this.sucessTraerArticulo2                       = this.sucessTraerArticulo2.bind(this);
        this.traerinformacion                           = this.traerinformacion.bind(this);
        this.success_traerinformacion                   = this.success_traerinformacion.bind(this);
        this.datos_articulo_aux                         = [];
        this.traerInfoArticulo                          = this.traerInfoArticulo.bind(this);
        this.sucessTraerArticulo                        = this.sucessTraerArticulo.bind(this);
        this.mostrartabla                               = this.mostrartabla.bind(this);
        this.successmostrartabla                        = this.successmostrartabla.bind(this);
        this.gridOptionsComponentes                     = Object.assign({}, this.gridOptions);
        this.mostrar                                    = this.mostrar.bind(this);
        this.successmostrar                             = this.successmostrar.bind(this);
        this.selectedRows                               = [];
        this.tabla_fletes                               = this.tabla_fletes.bind(this);
        this.successtabla_fletes                        = this.successtabla_fletes.bind(this);
        this.traerCiudades                              = this.traerCiudades.bind(this);
        this.successTraerCiudades                       = this.successTraerCiudades.bind(this);
        this.consultar_costo                            = this.consultar_costo.bind(this);
        this.successconsultarcosto                      = this.successconsultarcosto.bind(this);
        this.cambiarcosto                               = this.cambiarcosto.bind(this);
        this.successcambiarcosto                        = this.successcambiarcosto.bind(this);
        this.successconsultarcosto                      = this.successconsultarcosto.bind(this);
        this.cambiarcosto                               = this.cambiarcosto.bind(this);
        this.successcambiarcosto                        = this.successcambiarcosto.bind(this);
        this.mas_precios                                = this.mas_precios.bind(this);
        this.successmasprecios                          = this.successmasprecios.bind(this);
        this.cambiarprecios                             = this.cambiarprecios.bind(this);
        this.successcambiarprecios                      = this.successcambiarprecios.bind(this);
        this.selectprecio                               = ""; 
        this.selectbodega                               = "";
        this.otrabodega                                 = ""; 
        this.selectciudad                               = ""; 
        this.mostrarMensajeEmailEnviado                 = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo                   = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos                   = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3                           = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3                         = this.generarExcelTabla3.bind(this);
        this.enviarCorreoTerceros                       = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                        = this.successEnviarCorreo.bind(this);
        this.isJson                                     = this.isJson.bind(this);
        this.imprimir                                   = this.imprimir.bind(this);
        this.generarModalLista                          = this.generarModalLista.bind(this);
        this.sucessTraerDatos                           = this.sucessTraerDatos.bind(this);
        this.abrirImprimir                              = this.abrirImprimir.bind(this);
        this.habilitarCorreo                            = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                         = this.deshabilitarCorreo.bind(this);
        this.limpiar                                    = this.limpiar.bind(this);
        this.cambiarprecio                              = this.cambiarprecio.bind(this);
        this.successConsultaCodigo                      = this.successConsultaCodigo.bind(this);
        this.consultardatos                             = this.consultardatos.bind(this);
        this.successConsultaDatos                       = this.successConsultaDatos.bind(this);
        this.modificarprecio                            = this.modificarprecio.bind(this);
        this.successmodificarprecio                     = this.successmodificarprecio.bind(this);
        this.listanovedades                             = this.listanovedades.bind(this);
        this.successListaNovedades                      = this.successListaNovedades.bind(this);
        this.validarfechas                              = this.validarfechas.bind(this);
        this.eliminar_tabla                             = this.eliminar_tabla.bind(this);
        this.successeliminar_tabla                      = this.successeliminar_tabla.bind(this);
        
    }

    initForm() {

        console.log("Formulario CustomModificarPrecio,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.traerinformacion();
        this.getField("nombre_articulo").setOnChange(this.limpiar);
        this.getField("nombre_grupo").setOnChange(this.limpiar);
        this.getField("nombre_subgrupo").setOnChange(this.limpiar);
        this.getField("nombre_marca").setOnChange(this.limpiar);

        this.getField("codigo_articulo_aux").setOnChange(()=>{
            if(this.getField('codigo_articulo_aux').getValue()!=='' && this.prioridad_barras==='S' && this.cambiar_prioridad!==false){
                this.validarCodigo();
            }else if(this.prioridad_barras!=='S'){
                //aquí no tiene prioridad entonces trae directamente el artículo
                if(this.getField('codigo_articulo_aux').getValue()!=='' && this.respuesta_completa===true && this.datos_articulo_aux.length<=1){
                    this.sucessTraerArticulo2(this.datos_articulo_aux);
                }else if(this.datos_articulo_aux.length>1 && this.getField("codigo_articulo_aux").getValue()===this.getField("codigo_articulo").getValue()){
                    this.sucessTraerArticulo2(this.datos_articulo_aux);
                }
            }
        });
    
        this.getField("codigo_articulo").setOnChange(()=>{
            this.respuesta_completa=false;
            this.getField('nombre_articulo').setError(false,'');
            this.getField('nombre_articulo').setValue('');
        });
    
        this.getField("codigo_articulo").setCustomSuccess((props)=>{
            this.datos_articulo_aux=props;
            this.respuesta_completa=true;
            if(this.prioridad_barras!=='S'){
                //para forzar a consumir de nuevo el onchange
                this.getField("codigo_articulo_aux").setValue('cod_aux');
            }
            if(props.length<=1 && this.operacion_seleccionada!=='F9'){
                if(this.prioridad_barras==='S'){
                    this.cambiar_prioridad=false;
                    this.validarCodigo();
                }else{
                    this.cambiar_prioridad=true;
                }
            }else{
                this.cambiar_prioridad=true;
            }
        });
    
        //sobreescrivir keyups
        this.getField('codigo_articulo').setKeyUp((e)=>{this.changeKeyUpCustom(e)});
        this.getField("procesar").setClick(this.mostrartabla);
        this.getField("consultar").setClick(this.mostrar);
        this.getField("listado_precios").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.servicio="listaprecios";
                this.getField("otra_bodega").setVisible(true);
                this.getField('modal_informativa').handleClickOpen();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        this.getField("aceptar").setClick(()=>{
            if(this.getField("select_precios").valid() && this.getField("select_bodega").valid()){
                if(this.servicio === 'listaprecios'){
                    this.tabla_fletes();
                }else if(this.servicio === 'lista_marca'){
                    this.abrirImprimir();
                }else if(this.servicio === 'lista_novedades'){
                    this.abrirImprimir();
                }
            }
        });
        this.getField("aceptar1").setClick(this.abrirImprimir);
        this.getField("listado_precios_marcas").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.servicio="lista_marca";
                this.getField('modal_informativa').handleClickOpen();
                this.getField("otra_bodega").setVisible(false);
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        this.getField('cancelar').setClick(()=>{this.getField('modal_informativa').handleClose()});

        this.getField("select_precios").setOnBlur(()=>{this.selectprecio = this.getField("select_precios").getValue();})
        this.getField("select_bodega").setOnBlur(()=>{this.selectbodega = this.getField("select_bodega").getValue();})
        this.getField("ciudad_id").setOnBlur(()=>{this.selectciudad = this.getField("ciudad_id").getValue();})

        this.getField("cambiar_costo").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.consultar_costo();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        this.getField('cancelar1').setClick(()=>{this.getField('modal_costo').handleClose()});
        this.getField("aceptar2").setClick(()=>{
            if(this.getField("costo").valid()){
                this.cambiarcosto();
            }
        });

        this.getField("listado_novedades").setClick(()=>{
            this.getField('modal_fechas').handleClickOpen();
        });

        this.getField('cancelar3').setClick(()=>{this.getField('modal_fechas').handleClose()});
        this.getField("aceptar4").setClick(()=>{
            this.listanovedades();
        });

        this.getField("mas_precios").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.mas_precios();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });

        this.getField('cancelar2').setClick(()=>{this.getField('modal_mas_precios').handleClose()});
        this.getField("aceptar3").setClick(()=>{
            if(this.getField("precio1").valid() && this.getField("precio2").valid() && this.getField("precio3").valid() && this.getField("precio4").valid()
            && this.getField("precio5").valid() && this.getField("precio6").valid() && this.getField("precio7").valid() && this.getField("precio8").valid()
            && this.getField("precio9").valid() && this.getField("precio10").valid()){
                this.cambiarprecios();
            }
        });

        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);

        this.getField('generar_archivo').setClick(()=>this.imprimir());
        this.getField('modal_imprimir').setCloseButton(()=>{
        });

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

        this.getField("modal_imprimir").setCloseButton(this.eliminar_tabla)
    }


    validarfechas(){
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");
        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }
        this.fecha_actual = fecha_hasta;
        this.fecha_desde = fecha_desde;
        
        return true;

    }


    traerinformacion(){
        let datos={ datos: {
            sucursal_ingreso:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'informacion', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_traerinformacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    success_traerinformacion(data){
        if (data.estado_p === 200)
        {
            this.mostrar_pantalla = data.data.mostrar_pantalla;
            this.mostrar_financiero = data.data.mostrar_financiero;
            this.costo_por_bodega = data.data.costo_por_bodega;
            this.muestra_costo = data.data.muestra_costo;
            this.costo_unificado = data.data.costo_unificado;
            this.Bodega = data.data.Bodega;
            this.Supermercado = data.data.Supermercado;
            this.costo_promedio_linea = data.data.costo_promedio_linea;
            this.cosecha_pereira = data.data.cosecha_pereira;
            this.incluir_saldo_cero = data.data.incluir_saldo_cero;
            this.prioridad_barras = data.data.prioridad_barras;
            this.permite_precio_menor = data.data.permite_precio_menor;

        }else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    changeKeyUpCustom = (e) =>{
        let keycode = (e.keyCode ? e.keyCode : e.which);
        //keycode 120 = f9_servicio;
        //keycode 13 = enter_servicio;
        if(keycode === 120){
            this.operacion_seleccionada = "F9";
        }else{
            this.operacion_seleccionada = "ENTER-BOTON"; 
        }
        this.getField("codigo_articulo").changeKeyUp(e);
    }

    validarCodigo(){
        if(this.getField("codigo_articulo").valid()){
            let datos = { datos: {
                codigo_articulo:this.getField("codigo_articulo").getValue(),
                sucursal_ingreso:this.codigo_sucursal,
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidarCodigo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
    }

    sucessValidarCodigo(data){
        if (data.estado_p === 200 )
        {
            this.getField('codigo_articulo').setValue(data.data.xcodigo);
            this.traerInfoArticulo();
        }else {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    sucessTraerArticulo2(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.length>0){
          //eso es para evitar consumir dos veces el servicio traer articulo cuando prioridad barras esta en N
              //ya que no sería necesari, se guardo la respuesta dle primer servicio y con eso lleno los datos
          if(this.getField('codigo_articulo').getValue()!==''){
              data.forEach((element)=>{
                  if(element.codigo_interno===this.getField('codigo_articulo').getValue()){
                    data[0]=element;
                    return;
                  }
              });
          }
          this.getField('nombre_articulo').setValue(data[0].nombre);
          this.datos_articulo_aux=[];    
        }else{
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
        }
    }

    traerInfoArticulo(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          value: this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
        
    sucessTraerArticulo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField('nombre_articulo').setValue(data.data[0].nombre);

        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    mostrartabla(){
        this.errores=0;
        if(this.getField("codigo_grupo").getValue() !== ""){
            if(this.getField("codigo_grupo").valid() && this.getField("nombre_grupo").valid()){
                this.errores=0
            }else{
                this.errores=1
            }
        }
        else if(this.getField("codigo_subgrupo").getValue() !== ""){
            if(this.getField("codigo_subgrupo").valid() && this.getField("nombre_subgrupo").valid()){
                this.errores=0
            }else{
                this.errores=1
            }
        }
        else if (this.getField("codigo_marca").getValue() !== ""){
            if(this.getField("codigo_marca").valid() && this.getField("nombre_marca").valid()){
                this.errores=0
            }else{
                this.errores=1
            }
        }
        else if(this.getField("codigo_articulo").getValue() !== ""){
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
                this.errores=0
            }else{
                this.errores=1
            }
        }
        else{
            this.errores=1;
        }

        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            this.getField('codigo_grupo').setError(false,'');
            this.getField('nombre_grupo').setError(false,'');
            this.getField('codigo_subgrupo').setError(false,'');
            this.getField('nombre_subgrupo').setError(false,'');
            this.getField('codigo_marca').setError(false,'');
            this.getField('nombre_marca').setError(false,'');

            let datos={ datos: {
                costo_unificado:this.costo_unificado,
                costo_promedio_linea:this.costo_promedio_linea,
                costo_por_bodega:this.costo_por_bodega,
                muestra_costo:this.muestra_costo,
                codigo_articulo:this.getField('codigo_articulo').getValue(),
                detalle:this.getField('detalle').getValue(),
                codigo_grupo:this.getField('codigo_grupo').getValue(),
                nombre_grupo:this.getField('nombre_grupo').getValue(),
                codigo_subgrupo:this.getField('codigo_subgrupo').getValue(),
                nombre_subgrupo:this.getField('nombre_subgrupo').getValue(),
                codigo_marca:this.getField('codigo_marca').getValue(),
                nombre_marca:this.getField('nombre_marca').getValue(),
            }};

            this.getField("rejilla").setTipoServicioOperacion("inve-modificaprecio-consultar");
            this.getField("rejilla").sendRequest(this.successmostrartabla, datos);
        }
    }

    successmostrartabla(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            
            this.gridOptionsComponentes['rowData'] = data.data.datos;
            this.items = data.data.datos;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };
            configCell.set('precio1', { cellRenderer: function (props) { return props.data.precio1 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio1' });
            configCell.set('precio2', { cellRenderer: function (props) { return props.data.precio2 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio2' });
            configCell.set('precio3', { cellRenderer: function (props) { return props.data.precio3 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio3' });
            configCell.set('precio4', { cellRenderer: function (props) { return props.data.precio4 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio4' });
            configCell.set('precio5', { cellRenderer: function (props) { return props.data.precio5 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio5' });
            configCell.set('precio6', { cellRenderer: function (props) { return props.data.precio6 }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarprecio, sortable: true, filter: false, field: 'precio6' });



            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
            this.getField('rejilla').toggle(true);
            
            this.getField('consultar').setVisible(true);
            this.getField('listado_precios').setVisible(true);
            this.getField('listado_precios_marcas').setVisible(true);
            this.getField('listado_novedades').setVisible(true);
            this.getField('cambiar_costo').setVisible(true);
            this.getField('mas_precios').setVisible(true);
            this.getField('procesar').setDisabled(true);

        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    cambiarprecio(props) {

        this.antiguo_valor = props.oldValue;
        this.nuevo_valor = props.newValue;
        this.impconsumo = props.data.imp_consumo;
        this.codigo = props.data.codigo;
        this.columna = props.column.colId;
        this.nombre = props.data.nombre;

        let datos = { datos: {
            codigo_articulo: props.data.codigo
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'codigo_alterno', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successConsultaCodigo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successConsultaCodigo(data){
        if(data.estado_p === 200){ 

            this.getField('confirmModalCustom').setTitleAndContent('Error', 'Producto Alterno.');
            this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

        }else if(data.estado_p === 404){
            this.consultardatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    consultardatos(){
        let datos = { datos: {
            viejo_precio:this.antiguo_valor,
            codigo:this.codigo,
            costo_unificado:this.costo_unificado,
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'consultadatos', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successConsultaDatos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successConsultaDatos(data){
        if(data.estado_p === 200){ 
            this.getField('confirmModalCustom').setTitleAndContent('Informativo', 'IMPUESTO AL CONSUMO: '+this.impconsumo + ' Se dispone a modificar el '+this.columna+
                ': $' + this.currencyFormatterGeneral(this.antiguo_valor) + ' - La rentabilidad actual es de % ' + data.data.rentabilidad.toFixed(2) + ' - El nuevo precio es: $'+ this.currencyFormatterGeneral(this.nuevo_valor)
            );
            this.getField('confirmModalCustom').setClickDialog(() => {
                
                let valor_nuevo_precio = this.nuevo_valor - this.impconsumo;

                if (data.data.incluye_iva != null && data.data.incluye_iva === "S") {
                    valor_nuevo_precio = valor_nuevo_precio / (1.0 + (parseFloat(data.data.tarifa_iva) / 100.0));
                }

                if ((valor_nuevo_precio) < parseFloat(data.data.costo) ) {
                    this.getField('confirmModalCustom').setTitleAndContent('Error', 'El precio no debe ser menor que el costo ('+this.currencyFormatterGeneral(parseFloat(data.data.costo))+').');
                    this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);this.mostrartabla();});
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);                   
                }else{
                    this.modificarprecio();
                }
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.getField('confirmModalCustom').toggle(false);
                this.mostrartabla();
            });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`);
            this.getField("confirmModalCustom").setButtonCancel(`Cancelar`);
            this.getField('confirmModalCustom').toggle(true);

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    modificarprecio(){
        let datos={ 
            datos: {
                columna:this.columna,
                nuevo_valor:this.nuevo_valor,
                codigo:this.codigo,
                viejo_precio:this.antiguo_valor,
                nombre:this.nombre,
                usuario:this.codigo_usuario
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'modificarprecio', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successmodificarprecio,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successmodificarprecio(data){
        if (data.estado_p === 200 ) {
            this.mostrartabla();
        }else if(data.estado_p === 404){
            this.mostrartabla();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }



    limpiar(){
        this.getField('procesar').setDisabled(false);
        this.getField('listado_precios_marcas').setVisible(false);
        this.getField('listado_novedades').setVisible(false);
        this.getField('cambiar_costo').setVisible(false);
        this.getField('mas_precios').setVisible(false);
        this.getField('consultar').setVisible(false);
        this.getField('mas_precios').setVisible(false);
        this.getField('listado_precios').setVisible(false);
        this.selectedRows = [];

        this.gridOptionsComponentes['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsComponentes);
        this.getField('rejilla').toggle(false);
    }

    mostrar(){
        if(this.selectedRows.length > 0){
            let datos = { datos: {
                costo_unificado:this.costo_unificado,
                Supermercado:this.Supermercado,
                Bodega:this.Bodega,
                codigo:this.selectedRows[0].codigo
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'mostrar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successmostrar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });        
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    }

    successmostrar(data){
        if(data.estado_p === 200){ 

            this.getField('confirmModalCustom').setTitleAndContent('Informativo', 'Costo Artículo: $'+this.currencyFormatterGeneral(data.data.datos[0].costo)+' - Iva: '+data.data.datos[0].viva+ ' - Supermercado: '+data.data.datos[0].supermercado.toFixed(1)+' - Bodega: '+data.data.datos[0].bodega.toFixed(1)+'.');
            this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }
    
    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    tabla_fletes(){
        let datos = { datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'tabla_existe', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtabla_fletes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });   
    }

    successtabla_fletes(data){
        if(data.estado_p === 200 ){
            if(data.data[0].existe !== 0){
                this.traerCiudades();
                this.getField('modal_ciudades').handleClickOpen();
            }else{
                this.abrirImprimir();
            }
        }else if(data.estado_p === 404){
            this.listaprecios();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    traerCiudades(){
        let datos={ 
            datos: {} 
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerCiudades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successTraerCiudades(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('ciudad_id').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.ciudades_id;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_ciudad: item.codigo_ciudad};
                opciones.push(dataOp);
            });
            this.getField('ciudad_id').setOptions(opciones);
        } else {
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }

    consultar_costo(){
        let datos={ 
            datos: {
                costo_unificado:this.costo_unificado,
                codigo:this.selectedRows[0].codigo
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'consulta_costo', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successconsultarcosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successconsultarcosto(data){
        if (data.estado_p === 200 ) {
            this.getField("modal_costo").handleClickOpen();
            this.getField("costo_anterior").setValue(this.currencyFormatterGeneral(data.data.costo));

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    cambiarcosto(){
        let costo_anterior = this.getField("costo_anterior").getValue().replaceAll(".",'').replace(",",'.')
        let datos={ 
            datos: {
                costo_unificado:this.costo_unificado,
                costo_anterior:parseFloat(costo_anterior),
                costo:this.getField("costo").getValue(),
                codigo:this.selectedRows[0].codigo,
                usuario:this.codigo_usuario
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'cambio_costo', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successcambiarcosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successcambiarcosto(data){
        if (data.estado_p === 200 ) {
            this.getField("modal_costo").handleClose();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    mas_precios(){
        let datos={ 
            datos: {
                codigo_articulo:this.selectedRows[0].codigo
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'consulta_precio', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successmasprecios,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successmasprecios(data){
        if (data.estado_p === 200 ) {
            this.getField("modal_mas_precios").handleClickOpen();
            this.getField("titulo").setValue(data.data[0].codigo + " - "+data.data[0].nombre);
            this.getField("precio1").setValue(data.data[0].precio1);
            this.getField("precio2").setValue(data.data[0].precio2);
            this.getField("precio3").setValue(data.data[0].precio3);
            this.getField("precio4").setValue(data.data[0].precio4);
            this.getField("precio5").setValue(data.data[0].precio5);
            this.getField("precio6").setValue(data.data[0].precio6);
            this.getField("precio7").setValue(data.data[0].precio7);
            this.getField("precio8").setValue(data.data[0].precio8);
            this.getField("precio9").setValue(data.data[0].precio9);
            this.getField("precio10").setValue(data.data[0].precio1);

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }
    
    cambiarprecios(){
        let datos={ 
            datos: {
                precio1:this.getField("precio1").getValue(),
                precio2:this.getField("precio2").getValue(),
                precio3:this.getField("precio3").getValue(),
                precio4:this.getField("precio4").getValue(),
                precio5:this.getField("precio5").getValue(),
                precio6:this.getField("precio6").getValue(),
                precio7:this.getField("precio7").getValue(),
                precio8:this.getField("precio8").getValue(),
                precio9:this.getField("precio9").getValue(),
                precio10:this.getField("precio10").getValue(),
                codigo:this.selectedRows[0].codigo
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'cambio_precio', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successcambiarprecios,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false 
        });
    }

    successcambiarprecios(data){
        if (data.estado_p === 200 ) {
            this.getField("modal_mas_precios").handleClose();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    listanovedades() {

        let datos = { datos: {
            fecha_desde: this.fecha_desde,
            fecha_hasta: this.fecha_actual
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'listanovedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successListaNovedades,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successListaNovedades(data){
        if(data.estado_p === 200){ 
            this.getField('modal_fechas').handleClose()
            this.servicio = 'lista_novedades'
            this.getField("otra_bodega").setVisible(true);
            this.getField('modal_informativa').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    eliminar_tabla() {

        let datos = { datos: { }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: 'eliminar_tabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successeliminar_tabla,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successeliminar_tabla(data){
        if(data.estado_p === 200){ 
            this.getField('modal_imprimir').handleClose();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    abrirImprimir(){
        this.getField('modal_informativa').handleClose();
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    imprimir(){
        let errores =0;
        if(this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos = {}
        if(this.servicio === 'listaprecios'){
            datos = { datos : { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
        }else if(this.servicio === 'lista_marca'){
            datos = { datos: {
                codigo_marca:this.getField("codigo_marca").getValue(),
                codigo_usuario:this.codigo_usuario,
                sucursal_ingreso:this.codigo_sucursal,
                operacion: this.operacion_actual,
                precio: this.selectprecio,
                saldo: this.selectbodega
            }};
        }else if(this.servicio === 'lista_novedades'){
            datos = { datos : { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
        }
        this.generalFormatPmv = { tipo_servicio: 'inve-modificaprecio', operacion: this.servicio+"_pag", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla3()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2;
        if(this.servicio === 'listaprecios'){
            datos2 = { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === 'lista_marca'){
            datos2 = { 
                codigo_marca:this.getField("codigo_marca").getValue(),
                codigo_usuario:this.codigo_usuario,
                sucursal_ingreso:this.codigo_sucursal,
                operacion: this.operacion_actual,
                precio: this.selectprecio,
                saldo: this.selectbodega,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === 'lista_novedades'){
            datos2 = { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-modificaprecio","operacion":"'+this.servicio+'_pag","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2;
        if(this.servicio === 'listaprecios'){
            datos2 = { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === 'lista_marca'){
            datos2 = { 
                codigo_marca:this.getField("codigo_marca").getValue(),
                codigo_usuario:this.codigo_usuario,
                sucursal_ingreso:this.codigo_sucursal,
                operacion: this.operacion_actual,
                precio: this.selectprecio,
                saldo: this.selectbodega,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === 'lista_novedades'){
            datos2 = { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-modificaprecio","operacion":"'+this.servicio+'_pag","operacion_tipo":"consulta"}}';
        
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.servicio+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        if(this.servicio === 'listaprecios'){
            let datos = { datos : { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
            this.getField("tabla_consultar").setTipoServicioOperacion("inve-modificaprecio-"+this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);

        }else if(this.servicio === 'lista_marca'){
            let datos = { datos: {
                codigo_marca:this.getField("codigo_marca").getValue(),
                codigo_usuario:this.codigo_usuario,
                sucursal_ingreso:this.codigo_sucursal,
                operacion: this.operacion_actual,
                precio: this.selectprecio,
                saldo: this.selectbodega
            }};
            this.getField("tabla_consultar").setTipoServicioOperacion("inve-modificaprecio-"+this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);
        }else if(this.servicio === 'lista_novedades'){
            let datos = { datos : { 
                incluir_saldo_cero:this.incluir_saldo_cero,
                select_precio:this.selectprecio,
                select_bodega :this.selectbodega,
                otra_bodega :this.otrabodega,
                ciudad: this.selectciudad,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
            this.getField("tabla_consultar").setTipoServicioOperacion("inve-modificaprecio-"+this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);

        }

    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista = data.data.datos_lista;
            let data2 =
            {
                "empresa": this.lista.empresa,
                "nit": this.lista.nit,
                "direccion": this.lista.direccion,
                "pbx": this.lista.pbx,
                "nit_xml": this.lista.nit_xml,
                "titulo": this.lista.titulo,
                "fecha_sistema": this.lista.fecha_sistema
            };

            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);

            this.getField('tabla_consultar').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos_items;
            this.getField('tabla_consultar').initData(this.gridOptionsComponentes, configCell);

            this.getField('modal_general').handleClickOpen();

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }
   
}
FormJaivana.addController("inve-modificaprecio", CustomModificarPrecio);
export default CustomModificarPrecio;
